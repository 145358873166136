<template>
  <div class="content">


    <div class="slide1">
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide :key="index" class="swiperSlide" v-for="(item,index) in swiperList">
          <a :href="item.link" target="_blank"><img :src="$url+item.image"   alt=""></a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>


    <div class="view">
<!--      <h1>合作咨询</h1>-->
      <div style="width:100%;"> <img class="team" style="width:197px;height:50px;" src="../assets/images/hezuo.png"></div>
<!--      <loading :empty="empty" :loading="loading"></loading>-->
      <div class="list">
        <div  class="item" >
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="咨询内容">
              <el-select v-model="form.region" placeholder="咨询内容">
                <el-option label="承办赛事" value="0"></el-option>
                <el-option label="报名参赛" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地区">
              <el-cascader
                  size="large"
                  :options="options"
                  v-model="selectedOptions"
                  @change="handleChange">
              </el-cascader>

            </el-form-item>
            <el-form-item label="留言"  style="height:90px;">
              <el-input type="textarea" style="height:80px;" rows="4"  v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item >

              <el-button type="primary" size="medium"
                         style="display:block;width:50%;margin:0 auto;background:#01b0a0;border:1px solid #01b0a0;"
                         @click="onSubmit()">提交
              </el-button>


            </el-form-item>
          </el-form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { regionData  } from 'element-china-area-data'

export default {
  name: "academicExchange",
  data() {
    return {
      list: [],
      nowIndexs: 1,
      sumPage: 1,
      empty: false,
      swiperList:[],
      form: {
        name: '',
        phone: '',
        region: '',
        desc: '',
        province: '',
        city: '',
        area: '',
      },
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 5000,
        },

      },
      options: regionData,
      selectedOptions: []
    }
  },
  methods: {
    getBanner() {
      this.$get('index/banner')
          .then(res => {
            this.swiperList = res.data
          })
    },
    fetchData() {
      this.$get('index/news', {params: {type: 3, page: this.nowIndexs}})
          .then(res => {
            let arr = this.list
            if (res.data) {
              res.data.data.map(c => {
                arr.push(c)
              })
              this.sumPage = res.data.last_page
            } else {
              this.empty = true
            }

          })
    },
    onSubmit(){
      if (this.form.name == "") {
        this.$message.error('姓名不能为空');
        return false
      } else if (this.form.phone == "") {
        this.$message.error('电话号不能为空');
        return false
      } else if (this.form.region == "") {
        this.$message.error('咨询内容不能为空');
        return false
      }else if (this.form.desc == "") {
        this.$message.error('留言内容不能为空');
        return false
      }else if (this.form.province == "") {
        this.$message.error('地区不能为空');
        return false
      }
      this.$post('index/Addcooperation', this.form)
          .then(res => {
              if(res.code == 1){
                this.$message({
                  showClose: true,
                  message: '提交成功!',
                  type: 'success'
                });
              }

          })
    },
    handleChange (value) {

      this.form.province = value[0]
      this.form.city = value[1]
      this.form.area = value[2]
    },

    goPage(id) {
      this.$router.push({path:'/exchangeView',query:{id:id, type:2}})
    },
    seeMore() {
      if (this.sumPage == this.nowIndexs) {
        return
      }
      this.nowIndexs++
      this.fetchData()
    }
  },
  created() {
    this.getBanner()
    this.selectedOptions = [this.form.provinceCode,this.form.cityCode,this.form.areaCode];
   // this.fetchData()
  },

  filters: {
    time(dataStr) {
      if (!dataStr || dataStr == 0) {
        return '              -'
      } else {
        return moment(dataStr * 1000).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }

}
</script>

<style lang="less" scoped>

/deep/ .swiper-pagination-bullet-active {
  background: #fff;
}
/deep/ .el-input__inner{
  height:50px;
  //width:70%;
}
///deep/ .el-textarea{
//  width:70%;
//}
///deep/ .el-input__suffix{
//  height: 100%;
//  left: 64%;
//  transition: all .3s;
//  pointer-events: none;
//}
.team {
  //height: 7.8125vw;
  //width: 100%;
  display:block;
  margin: 0 auto;
  margin-top: 0.4792vw;
}
/deep/ .el-select{
  display: block;
  //width:70%;
}
/deep/ .textarea__inner{
  height:90px;
}
/deep/ .el-cascader{
  display: block;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 200px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.content {
  width: 100%;
  min-height:calc(100vh - 10.8333vw - 4.1667vw);
  .slide1 {
    width: 100%;
    height: 31.2500vw;
    background: #e8e7e7;

    .swiper, .swiperSlide, .swiperSlide img {
      width: 100%;
      height: 100%;
    }
  }
  .view {
    width: 52.0833vw;
    height: 700px;
    margin: 0 auto;
    padding-top: 4.1667vw;

    h1 {
      font-size: 2.0833vw;
      font-weight: bold;
      color: #333333;

    }

    .list {
      margin-top: 3.1250vw;

      .seeMore {
        text-align: center;
        font-size: 0.8333vw;
        line-height: 1.6667vw;
        margin-bottom: 2.6042vw;
        cursor: pointer;
        color: #797878;
      }

      .item {
        width: 60%;
        height: 100%;
        //float:right;
        margin:0 auto;
        //display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        //border-top: 0.0521vw solid #EEEEEE;
        overflow: hidden;

        img {
          width: 12.1875vw;
          height: 6.8229vw;
        }

        >div {
          height: 6.8229vw;
          margin-left: 4.1146vw;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          overflow: hidden;
          flex: 1;
          overflow: hidden;
          h1 {
            font-size: 1.0417vw;
            font-weight: 550;
            color: #333333;

          }

          div{
            margin-top: 1.2500vw;
            margin-bottom: 1.1458vw;
            span {
              font-size: 0.6250vw;
              font-weight: 550;
              color: #999999;
              margin-right: 2vw;
            }
          }

          p {
            width: 100%;
            height: 4.8229vw;
            font-size: 0.8333vw;
            font-weight: 550;
            color: #333333;
            line-height: 2.41vw;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            /deep/ p{
              font-size: 0.8333vw !important;
              line-height: 2.41vw !important;
              color: #333333 !important;
              height: 100%;
              white-space:nowrap;
              overflow:hidden;
              text-overflow:ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>

